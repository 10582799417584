import React from 'react';

import {
  Fab
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  ReactComponent as AnnotationToolsIcon
} from 'assets/ic_tools_disabled.svg';

// ...

const useStyles = makeStyles({
  disabled: {
    filter: 'brightness(50%)'
  }
});

const useButtonStyles = makeStyles(theme => ({
  button: {
    width: 36,
    height: 36,
    boxShadow: 'none'
  }
}));

// ...

function Button(props) {
  const styles = useButtonStyles();

  return (
    <Fab classes={{ root: styles.button }} {...props}>
      <AnnotationToolsIcon />
    </Fab>
  )
}

// ...

export function AnnotationButton({ mode, onFrameRequest }) {
  const styles = useStyles();

  switch (mode) {
    case 'enabled':
      return (
        <Button
          onClick={onFrameRequest}
        />
      )

    case 'disabled':
      return (
        <Button
          className={styles.disabled}
          disabled={true}
          onClick={onFrameRequest}
        />
      )

    default:
      return null;
  }
}