export const firebaseConfig = {
  apiKey: "AIzaSyChkbcV2EmDWxQnXahGXmvXzdCVzHPJiF8",
  authDomain: "lenovo-demo-367415.firebaseapp.com",
  databaseURL: "https://lenovo-demo-367415.firebaseio.com",
  projectId: "lenovo-demo-367415",
  storageBucket: "lenovo-demo-367415.appspot.com",
  messagingSenderId: "661553784476",
  appId: "1:661553784476:web:5357f0186bf6acbe33af59",
};

export const vapidKey = `BARX7uGby9FkErCfaCl6llqOFzU9bfXW-sckXBbglX6fJ0xEl3BAcz7CVttF1ZOXhibC2HIovZaPICbyARjbSGI`;

// ...

const region = "us-central1";

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === "true") {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  }

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}
