import React from 'react';

import {
  useLocation,
  useNavigate
} from 'react-router-dom';

import {
  Button,
  Grid,
  TextField,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik';

import * as Yup from 'yup';

import {
  useAuth,
  useError
} from 'hooks';

import {
  baseStyles
} from '../../baseStyles';

import { passwordRegex } from 'providers/AuthenticationProvider';

// ...

const useStyles = makeStyles(theme => ({
  button: {
    ...baseStyles.button,

    backgroundColor: theme.button.primary
  },

  buttonCancel: {
    ...baseStyles.button,

    backgroundColor: theme.button.secondary,
    marginRight: 10
  },

  container: {
    paddingLeft: '20%',
    paddingRight: '20%',
    marginTop: '10%',
    marginBottom: '10%'
  },

  header: {
    textAlign: 'center',
    paddingBottom: 25
  },

  field: {
    paddingBottom: 15
  }
}));

// ...
// Form settings.
const initialValues = {
  password: ''
};

const validationSchema = Yup.object().shape({
  password:
    Yup
      .string()
      .required('Required')
      .matches(
        passwordRegex,
        "Password must be at least 8 characters long, with no more than two consecutive identical characters, and contain one: uppercase letter, lowercase letter, digit (0-9), special character"
        ),
});

// ...

const errors = {
  'auth/expired-action-code': 'The password reset link has expired.',
  'auth/invalid-action-code': 'The password reset link is invalid. This can happen if the link has already been used',
  'auth/user-disabled': 'The user associated with the link has been disabled',
  'auth/user-not-found': 'The user associated with the link does not exist. This can happen if the user was deleted between when the link was sent and attempted to be used.',
  'auth/weak=password': 'The proposed password is too weak.',
};

// ...

export function NewPasswordForm({ actions }) {
  const styles = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const code = location.search
    .split("&")[1]
    .split("=")[1];


  const {
    confirmPasswordReset
  } = useAuth();

  const {
    notify
  } = useError();

  // ...

  const handleSubmit = async ({ password: newPassword }) => {
    try {
      await confirmPasswordReset(code, newPassword);

      notify('Password reset confirmed.');

      const to = location.state?.from ?? '/';
      navigate(to);

    } catch (error) {
      notify(errors[error.code] ?? 'Unknown error when resetting password');
    }
  };

  const handleCancel = async () => {
    try {
      const to = location.state?.from ?? '/';
      navigate(to);
    } catch (error) {
      notify()
    }
  }

  // ...

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}

      onSubmit={handleSubmit}

      validateOnChange
    >
      {({ values, dirty, isValid, isSubmitting }) => (
        <Grid
          className={styles.container}
          container
          direction="column"
        >
          <Grid item>
            <T className={styles.header} variant="h5">
              ENTER NEW PASSWORD
            </T>
          </Grid>
          <Grid item>
            <Form
              noValidate
              autoComplete="off"
            >
              <Field
                name="password"
                type="password"
                placeholder="New Password"

                as={TextField}
                className={styles.field}
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="password" />}
              />

              <Grid container justify="space-between">
                <Button
                  className={styles.buttonCancel}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  className={styles.button}
                  type="submit"
                  disabled={!(dirty && isValid) || isSubmitting}
                  disableElevation
                >
                  SUBMIT NEW PASSWORD
                </Button>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}