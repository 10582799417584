import AgoraRtc from "agora-rtc-sdk-ng";

// ...

let uid;

let rtcClient;

let subscriptions = {};

let listeners = {};

// note: Audience members publish audio tracks only.
let localTrack;

// ...

function onEvent(event, ...args) {
  const callbacks = listeners[event];
  if (callbacks) {
    callbacks.forEach(callback => callback(...args));
  }
}

// ...

function join(channelId, token, _listeners) {
  // Specify the region for connection 
  AgoraRtc.setArea({
    areaCode: process.env.REACT_APP_RTC_AREA_CODE
  })

  rtcClient = AgoraRtc.createClient({ mode: 'rtc', codec: 'vp8' });
  rtcClient.setEncryptionConfig("aes-256-xts", process.env.REACT_APP_TOKEN_SECRET);

  Object.entries(_listeners).forEach(([eventName, listener]) => rtcClient.on(eventName, listener));

  // ...

  rtcClient.on('user-published', async (user, mediaType) => {
    await rtcClient.subscribe(user, mediaType);

    // ...
    const _uid = user.id;

    if (!subscriptions[_uid]) {
      subscriptions[_uid] = {};
    }

    if (mediaType === 'video') {
      subscriptions[_uid].video = user.videoTrack;

      onEvent('user-published', _uid, 'video');
    }

    if (mediaType === 'audio') {
      subscriptions[_uid].audio = user.audioTrack;

      onEvent('user-published', _uid, 'audio');
    }
  });

  rtcClient.on('user-unpublished', (user, mediaType) => {
    delete subscriptions[user.uid][mediaType];

    onEvent('user-unpublished', user.uid, mediaType);
  });

  // ...

  return rtcClient.join(process.env.REACT_APP_AGORA_APP_ID, channelId, token, uid);
}

function leave() {
  if (localTrack) {
    localTrack.stop();
    localTrack.close();

    localTrack = null;
  }

  // ...

  Object.values(subscriptions).forEach(({ audio, video }) => {
    if (audio) {
      audio.stop();
    }

    if (video) {
      video.stop();
    }
  });

  subscriptions = {};
  listeners = {};

  // ...

  if (rtcClient) {
    rtcClient.leave();
    rtcClient = null;
  }
}

function onUserPublished(listener) {
  if (!listeners['user-published']) {
    listeners['user-published'] = [];
  }

  listeners['user-published'].push(listener);

  for (const userId in subscriptions) {
    const {
      audio,
      video
    } = subscriptions[userId];

    if (video) {
      listener(userId, 'video');
    }

    if (audio) {
      listener(userId, 'audio');
    }
  }
}

function onUserUnpublished(listener) {
  if (!listeners['user-unpublished']) {
    listeners['user-unpublished'] = [];
  }

  listeners['user-unpublished'].push(listener);
}

function hasRemoteAudio(_uid) {
  return !!subscriptions[_uid]?.audio;
}

function hasRemoteVideo(_uid) {
  return !!subscriptions[_uid]?.video;
}

function playRemoteAudio(_uid) {
  const track = subscriptions[_uid]?.audio;
  if (!track) {
    return;
  }

  if (!track.isPlaying) {
    track.play();
  }
}

function playRemoteVideo(_uid, container) {
  const track = subscriptions[_uid]?.video;
  if (!track) {
    return;
  }

  if (!track.isPlaying) {
    track.play(container);
  }
}

function stopRemoteVideo(_uid) {
  const track = subscriptions[_uid]?.video;
  if (!track) {
    return;
  }

  if (track.isPlaying) {
    track.stop();
  }
}

async function playAudio() {
  if (localTrack) {
    return;
  }

  localTrack = await AgoraRtc.createMicrophoneAudioTrack();

  rtcClient.publish(localTrack);
}

function setMute(shouldMute) {
  if (localTrack) {
    const volume = shouldMute ? 0 : 100;
    localTrack.setVolume(volume);
  }
}

function setUid(_uid) {
  uid = _uid;
}

function getUid() {
  return uid;
}

// ...

const rtc = {
  setUid,
  getUid,

  join,
  leave,

  onUserPublished,
  onUserUnpublished,

  hasRemoteAudio,
  hasRemoteVideo,

  playRemoteAudio,
  playRemoteVideo,
  stopRemoteVideo,

  playAudio,
  setMute
};

export default rtc;