import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';

// ...
// Custom Vision Beyond theme.
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Rubik, sans-serif'
  },

  // ...
  // Custom theme extensions.

  button: {
    primary: '#39BBBB',
    secondary: '#EE6B4E'
  }
});

// ...

export function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
