import React, {
  useCallback,
  useEffect,
  useState
} from 'react';

import {
  useService
} from '@xstate/react';

import {
  makeStyles
} from '@material-ui/core/styles';

import clsx from 'clsx';

import {
  useValue,
  useQuery,
  useAuth
} from 'hooks';

import * as firebase from 'services/firebase';

import {
  baseStyles
} from '../../components/baseStyles';

import {
  Toolbar
} from '../../components/Toolbar';

import {
  useAudio
} from './useAudio';

import {
  useVideo
} from './useVideo';

import {
  Controls
} from './Controls';

import {
  LiveAnnotation
} from './views';

// ...

const {
  fullscreen,
} = baseStyles;

const useStyles = makeStyles({
  background: {

    zIndex: 1,
    backgroundColor: 'rgb(210, 210, 210)',
    width: "100%",
    height: "100%"
  },

  overlay: {
    zIndex: 2,

    display: 'flex',
    flexDirection: 'column'
  },

  fullscreen: {
    ...fullscreen
  },

  hide: {
    visibility: 'hidden'
  },

  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },

  text: {
    visibility: 'visible',
    fontSize: 24,
    color: 'white',
    textAlign: 'center'
  },

});

// ...

/**
 * Fits the video to the full browser window and centers it over the screen. The video
 * will scale appropriately so that it is always using as much space as possible, however
 * the viewport aspect ratio may change. If it does, the video will be stretched to fill
 * the shorter dimension first, and then adapt and center over the longer dimension.
 */
function fitVideo_fullscreen() {

  // This element should fit in the fullscreen boundaries.
  let elBg = document.getElementById('video-background');
  if (!elBg) {
    return null;
  }
  let rect = elBg.getBoundingClientRect()

  // Aspect ratios
  let videoRatio = 16 / 9

  // Aspect ratio of the video container.
  let windowWidth = rect.width
  let windowHeight = rect.height
  let windowRatio = windowWidth / windowHeight

  // Calculate these based on the size of the window. These values will be applied as the
  // new boundaries of the windowed video.
  let videoWidth = 0
  let videoHeight = 0
  let videoTop = 0
  let videoLeft = 0

  // Determine how to position the fullscreen video.
  if (windowRatio > videoRatio) {

    // Video will show whitespace if use full height, so constrain to the width of the frame 
    // and allow the height of the video to centered and clipped instead.
    videoWidth = rect.width
    videoHeight = (videoWidth * 9) / 16

  }
  else {

    // Video will show whitespace if use full width, so constrain to the height of the frame 
    // and allow the width of the video to centered and clipped instead.
    videoHeight = rect.height
    videoWidth = (videoHeight * 16) / 9

  }

  // Calculate offsets to center container to view
  videoLeft = (windowWidth - videoWidth) / 2
  videoTop = (windowHeight - videoHeight) / 2

  // Apply to the fullscreen video container.
  let el = document.getElementById('video-container');
  if (el) {

    // Apply to element.
    el.style.position = "absolute"
    el.style.width = videoWidth + 'px'
    el.style.height = videoHeight + 'px'
    el.style.left = videoLeft + 'px'
    el.style.top = videoTop + 'px'

    return el.getBoundingClientRect()
  }
  return null
}

/**
* Fits the video to fixed inset boundaries, and centers it over the screen. The video
* will scale appropriately so that it is always using as much space as possible while
* preserving aspect ratio.
*/
function fitVideo_windowed() {

  // Inset boundaries. Controls sit outside of these boundaries.
  let paddingLeft = 80
  let paddingRight = 110
  let paddingTop = 70
  let paddingBottom = 100

  // Aspect ratio of the raw video frame.
  let videoRatio = 16 / 9

  // Aspect ratio of the windowed video container.
  let windowWidth = window.innerWidth - (paddingRight + paddingLeft)
  let windowHeight = window.innerHeight - (paddingBottom + paddingTop)
  let windowRatio = windowWidth / windowHeight

  // Calculate these based on the size of the window. These values will be applied as the
  // new boundaries of the windowed video.
  let videoWidth = 0
  let videoHeight = 0
  let videoTop = 0
  let videoLeft = 0

  if (windowRatio > videoRatio) {

    // Video will clip if we use full width, so constrain the video size to full height and
    // calculate the width accordingly.
    videoHeight = window.innerHeight - (paddingBottom + paddingTop)
    videoWidth = (videoHeight * 16) / 9

  }
  else {

    // Video will clip if we use full height, so constrain to the width of the full width and
    // calculate the height accordingly.
    videoWidth = window.innerWidth - (paddingRight + paddingLeft)
    videoHeight = (videoWidth * 9) / 16

  }

  // Center over bounds
  videoLeft = paddingLeft + (windowWidth - videoWidth) / 2
  videoTop = paddingTop + (windowHeight - videoHeight) / 2

  // Apply to the windowed video container.
  let el = document.getElementById('windowed-video-container');
  if (el) {

    let newRect = {
      x: videoLeft,
      y: videoTop,
      width: videoWidth,
      height: videoHeight
    }

    // Apply to element.
    el.style.position = "absolute"
    el.style.width = videoWidth + 'px'
    el.style.height = videoHeight + 'px'
    el.style.left = videoLeft + 'px'
    el.style.top = videoTop + 'px'

    return newRect;
  }
  return null

}

// ...

export function Call({ context, actions, service }) {
  const [state, send] = useService(service);
  const [isFullscreen, setFullscreen] = useState(true); // should be fullscreen by default
  const [videoAbsRect, setVideoAbsRect] = useState({ x: 0, y: 0, width: 1280, height: 720 });

  const styles = useStyles();

  const {
    user
  } = useAuth();
  // ...

  const {
    mute,
    setMute
  } = useAudio();

  let mode = useValue('video-play');

  useVideo(
    mode,
    () => {
      send({
        type: 'VIDEO_SUBSCRIBED',

        value: true
      });
    },
    frameGrab => {
      send({
        type: 'FRAME_GRAB',

        frameGrab
      });
    }
  );

  // ...

  useEffect(() => {

    /**
     * Fits the video div into the window as large as it can be while maintaining aspect ratio,
     * not clipping the contents of the video, and not colliding with border controls.
     */
    function fitVideo() {
      const rect = isFullscreen === true ? fitVideo_fullscreen() : fitVideo_windowed()
      if (rect !== null) {
        setVideoAbsRect(rect)
      }
    }

    window.addEventListener('resize', fitVideo)

    fitVideo();
  }, [isFullscreen]);

  // ...

  const titlesCollection = useQuery(
    useCallback(
      () => firebase.fetchCollection('titles'),
      []
    )
  );

  // ...
  // Determine background element to display.
  let backgroundElement = isFullscreen ?
    <div id="video-container">
      {!state.context.videoSubscribed // NOSONAR
        ? <div className={clsx(styles.text, styles.center)}>Waiting for video feed... <br></br> If this screen persists, your network may be blocking video transmission.</div>
        : null} 
    </div>
    :
    <div id='windowed-video-container' className={styles.windowedVideoContainer}>
      <div id='video-container'>
      </div>
    </div>

  // ...

  return (
    <>
      <div id="video-background" className={styles.background}>
        {backgroundElement}
      </div>

      {state.matches('live_annotating') && isFullscreen
        ? null
        : (
          <div className={clsx(styles.overlay, styles.fullscreen)}>
            <Toolbar avatarColors={context.memberColors} />
            <Controls
              context={{
                mute,
                members: context.members,
                memberColors: context.memberColors,
                titlesCollection,
                isFullscreen,
                setFullscreen,
                videoAbsRect,
              }}
              actions={{
                ...actions,
                setMute
              }}
              styleProps={{
                root: {
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }
              }}
            >
              <Controls.AnnotationButton
                mode={state.context.videoSubscribed ? 'enabled' : 'disabled'} // NOSONAR
                onFrameRequest={() => send('LIVE_ANNOTATION_START')}
              />
            </Controls>

          </div>
        )}
      {!state.matches("live_annotating")
        ? null
        : (
          <div className={styles.overlay}>
            <LiveAnnotation
              id="liveAnnotationControl"
              type={"HOTSPOT"}
              context={{
                videoAbsRect,
                isFullscreen
              }}
              style={{
                zIndex: 1000
              }}
              actions={{
                onDiscard() {
                  send('CANCEL');
                },
                onAnnotation(annotationType, annotationData) {
                  let annotationId = user.uid + annotationData.CreationTime
                  send({
                    type: 'LIVE_ANNOTATION',
                    data: {
                      type: annotationType,

                      ...annotationData,

                      referenceFrame: null,
                      id: annotationId
                    }
                  });
                },
                onEndAnnotation(annotationData) {
                  let annotationId = user.uid + annotationData.CreationTime
                  send({
                    type: 'LIVE_ANNOTATION_END',
                    data: {
                      id: annotationId
                    }
                  })
                }
              }}
              color={context.memberColors[user.uid]}
            />
          </div>
        )}
    </>
  );
}