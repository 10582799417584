import {
  assign,
  sendParent,

  Machine
} from 'xstate';

// ...
// Actions.
function getInitialContext() {
  return {
    videoSubscribed: false,
    frameData: null,
    frameGrab: null,
    annotationType: null
  }
}

const resetContext = assign((context, _event) => {
  return {
    ...getInitialContext(),

    videoSubscribed: context.videoSubscribed
  };
});
  
const videoSubscribed = assign({
  videoSubscribed: (_context, { value }) => value
});

const frameData = assign({
  frameData: (_context, { data }) => data
});

const frameGrab = assign({
  frameGrab: (_context, { _frameGrab }) => _frameGrab
});

const annotationType = assign({
  annotationType: (_context, event) => event.annotationType
});

const proxyEvent = sendParent((_context, event) => ({
  type: 'PROXY_EVENT',

  event
}));

// ...

export const callMachine = Machine(
  {
    id: 'call',
    context: getInitialContext(),
    initial: 'default',
    on: {
      CANCEL: 'default'
    },
    states: {
      default: {
        entry: 'resetContext',
        on: {
          VIDEO_SUBSCRIBED: {
            actions: 'videoSubscribed'
          },
          FRAME_REQUEST: {
            target: 'capturing',
            actions: 'proxyEvent'
          },
          LIVE_ANNOTATION_START : {
            target: 'live_annotating',
            // actions: 'proxyEvent'
          }
        }
      },
      capturing: {
        on: {
          FRAME_DATA: {
            actions: 'frameData'
          },
          FRAME_GRAB: {
            target: 'captured',
            actions: 'frameGrab'
          }
        }
      },
      captured: {
        on: {
          SELECT: {
            target: 'annotating',
            actions: 'annotationType'
          }
        }
      },
      annotating: {
        on: {
          ANNOTATION: {
            target: 'default',
            actions: 'proxyEvent'
          }
        }
      },
      live_annotating: {
        on: {
          LIVE_ANNOTATION: {
            target: 'live_annotating',
            actions: 'proxyEvent'
          },
          LIVE_ANNOTATION_END: {
            target: 'live_annotating',
            actions: 'proxyEvent'
          }
        }
      }
    }
  },
  {
    actions: {
      resetContext,

      videoSubscribed,
      
      frameData,
      frameGrab,

      annotationType,

      proxyEvent
    }
  }
);