import {
  useState
} from 'react';

import{
  useError
} from 'hooks';

// ...

export function useUpload() {
  const [
    upload,
    setUpload
  ] = useState(null);

  const {
    notify
  } = useError();

  // ...

  const handleUpload = file => {
    if (!file) {
      return setUpload(null);
    }
    
    if (file.size < 2 * 1024 * 1024) {
      const fr = new FileReader();
      fr.onload = () => {
        setUpload(fr.result);
      }

      fr.readAsDataURL(file);
    }
    else{
      notify("Profile picture must be smaller than 2 MB");
    }
  };

  // ...

  return [
    upload,
    handleUpload
  ];
}