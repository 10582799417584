import React from 'react';

import {
  ErrorContext
} from 'ErrorContext';

import {
  toast
} from 'react-toastify';

// ...

function notify(content) {
  toast.error(content);
}

// this notify will not close automatically, preventing users from potentially missing warnings

function longNotify(content) {
  toast.error(content, {
    autoClose: false
  });
}

// ...

export class ErrorHandler extends React.Component {
  state = {
    error: null
  };

  // ...

  retry = () => {
    this.setState({
      error: null
    });
  }

  capture = error => {
    this.setState({
      error
    });
  }

  trace = (error, errorInfo) => {
    console.log(error, errorInfo);
  }

  // ...

  static getDerivedStateFromError(error) {
    return {
      error
    };
  }

  componentDidCatch(error, errorInfo) {
    this.trace(error, errorInfo);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.error && prevState.error === this.state.error) {
      this.retry();
    }
  }

  render() {
    const {
      error
    } = this.state;
    
    if (error) {
      const {
        fallback: Fallback
      } = this.props;
      
      if (Fallback) {
        return <Fallback error={error} retry={this.retry} />
      } else {
        // note: A root boundary MUST have a fallback set.
        throw error;
      }
    }

    return (
      <ErrorContext.Provider value={{
        capture: this.capture,
        trace: this.trace,
        notify,
        longNotify
      }}>
        {this.props.children}
      </ErrorContext.Provider>
    )
  }
}