import React from 'react';

import {
  Button,
  Grid,
  TextField,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik';

import * as Yup from 'yup';

import {
  useAuth,
  useError
} from 'hooks';

import {
  isWhitelisted
} from 'services/firebase';

import {
  baseStyles
} from '../../baseStyles';
import { passwordRegex } from 'providers/AuthenticationProvider';

// ...

const useStyles = makeStyles(theme => ({
  button: {
    ...baseStyles.button,

    backgroundColor: theme.button.primary
  },

  buttonCancel: {
    ...baseStyles.button,

    backgroundColor: theme.button.secondary,
    marginRight: 10
  },

  container: {
    paddingLeft: '15%',
    paddingRight: '15%',
    marginTop: '10%',
    marginBottom: '10%'
  },

  header: {
    textAlign: 'center',
    paddingBottom: 25
  },

  field: {
    paddingBottom: 15
  }
}));

// ...
// Form settings.
const initialValues = {
  username: '',
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  username:
    Yup
      .string()
      .email('Invalid email address')
      .required('Required')
      .nullable(),
  password:
    Yup
      .string()
      .required('Required')
      .matches(
        passwordRegex,
        "Password must be at least 8 characters long, with no more than two consecutive identical characters, and contain one: uppercase letter, lowercase letter, digit (0-9), special character"
        ),
  passwordConfirmation:
    Yup
      .string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Required'),
});

// ...

export function NewUserCredentialsForm({ actions }) {
  const styles = useStyles();

  const {
    userExists
  } = useAuth();

  const {
    notify
  } = useError();

  // ...

  const validateUser = async (email) => {
    const [exists, whitelist] = await Promise.all([
      userExists(email),
      isWhitelisted(email)
    ]);

    return {
      exists,
      whitelist
    };
  }

  // ...

  const handleSubmit = async ({ username: email, password }) => {
    try {
      const {
        exists,
        whitelist
      } = await validateUser(email);

      if (exists) {
        notify('User with the provided email address already exists');
      } else if (!whitelist.isWhitelisted) {
        notify('User with the provided email address is not allowed to sign up')
      } else {
        const credentials = { username: email, password, AR: whitelist.AR };
        handlePreSignupSubmit(credentials);
      }

    } catch (error) {
      notify('Unknown authentication error');
      console.log(error);
    }
  };

  const {
    handleCancel,
    handlePreSignupSubmit
  } = actions;

  // ...

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}

      onSubmit={handleSubmit}

      validateOnChange
    >
      {({ values, dirty, isValid, isSubmitting }) => (
        <Grid
          className={styles.container}
          container
          direction="column"
        >
          <Grid item>
            <T className={styles.header} variant="h5">
              REGISTER NEW USER
            </T>
          </Grid>
          <Grid item>
            <Form
              noValidate
              autoComplete="off"
            >
              <Field
                name="username"
                placeholder="Email"

                as={TextField}
                className={styles.field}
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="username" />}
              />
              <Field
                name="password"
                type="password"
                placeholder="Password"

                as={TextField}
                className={styles.field}
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="password" />}
              />
              <Field
                name="passwordConfirmation"
                type="password"
                placeholder="Confirm Password"

                as={TextField}
                className={styles.field}
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="passwordConfirmation" />}
              />

              <Grid container justify="space-between">
                <Button
                  className={styles.buttonCancel}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  className={styles.button}
                  type="submit"
                  disabled={!(dirty && isValid) || isSubmitting}
                  disableElevation
                >
                  NEXT
                </Button>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}