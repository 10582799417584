import React from "react";

import { 
  Routes, 
  Route 
} from "react-router-dom";

import {
  RouteGuard
} from './components';

import {
  Auth,
  Error,
  Main,
  Profile
} from './pages';

// ...

export default function AppRoutes() {
  return (
    <Routes>
      <RouteGuard 
        path="/" 
        element={<Main />} 
      />
      <Route 
        path="profile" 
        element={<Profile />} 
      />
      <Route 
        path="auth" 
        element={<Auth />} 
      />
      <Route
        path="error"
        element={<Error />}
      />
      <Route 
        path="*"
        element={<div>Page not found</div>}
      />
    </Routes>
  );
}
