import {
  useEffect,
  useMemo
} from 'react';

import {
  useNavigate
} from 'react-router-dom'

import {
  useMachine
} from '@xstate/react';

import {
  useAuth,
  useError
} from 'hooks';

import {
  getAgoraService
} from 'services/agora';

import {
  getPushToken
} from 'services/firebase';

import {
  getToken
} from 'services/agora/token';

import {
  agoraMachine
} from 'state/agora';

import {
  agoraService
} from 'state/agoraService';

import {
  getActions
} from './getActions';

import {
  getView 
} from './getView';

// ...

export function Main() {
  const {
    user,
    profile,
    signOut
  } = useAuth();

  const {
    notify
  } = useError();

  const {
    navigate
  } = useNavigate();

  const [
    state,
    send
  ] = useMachine(agoraMachine, {
    services: {
      agoraService
    },

    devTools: true
  });

  // Connect to RTM and request to show permissions

  useEffect(() => {
    const init = async () => {
      if ('Notification' in window) {
        Promise.resolve(Notification.requestPermission()).then(permission => {
          if (permission === 'granted') {
            getPushToken(user.uid);
          } else {
            notify('Unable to get permission to notify.');
          }
        });
      }

      // Try to get token for RTM and using it to connect
      try{
        const rtmToken = await getToken({
          type: 'rtm',
          uid: user.uid
        });

        getAgoraService().rtm.login(rtmToken, user.uid);
        // Add event listener which detects when the session is forcefully ended (connectionState becomes "ABORTED")
        getAgoraService().rtm.on('ConnectionStateChanged', function(newState, reason) {
          if(newState === 'ABORTED'){
            notify("Detected log-in on other device or in other browser tab; logging out")
            signOut();
          }
      
        });
      } catch (error) {
        notify("Error connecting to RTM, logging out");
        await signOut();
        navigate("/")
      }
    };
    

    init();

    // ...

    return () => {
      getAgoraService().rtm.logout();
    }
  }, [user.uid, send]);
  
  // ...

  const rtcUid = profile?.rtcUid;

  useEffect(() => {
    if (!rtcUid) {
      return;
    }

    getAgoraService().rtc.setUid(rtcUid);
  }, [rtcUid]);

  // ... 

  const actions = useMemo(() => getActions(send), [send]);

  // ...

  return getView(state, actions);
}
