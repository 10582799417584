import React from 'react';

import {
  useLocation,
  useNavigate
} from 'react-router-dom';

import {
  Button,
  Grid,
  TextField,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik';

import * as Yup from 'yup';

import {
  useAuth,
  useError
} from 'hooks';

import {
  baseStyles
} from '../../baseStyles';

// ...

const useStyles = makeStyles(theme => ({
  button: {
    ...baseStyles.button,

    backgroundColor: theme.button.primary
  },

  buttonCancel: {
    ...baseStyles.button,

    backgroundColor: theme.button.secondary,
    marginRight: 10
  },

  container: {
    paddingLeft: '15%',
    paddingRight: '15%',
    marginTop: '10%',
    marginBottom: '10%'
  },

  header: {
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 25
  },

  field: {
    paddingBottom: 15
  }
}));

// ...
// Form settings.
const initialValues = {
  username: ''
};

const validationSchema = Yup.object().shape({
  username:
    Yup
      .string()
      .email('Invalid email address')
      .required('Required')
      .nullable(),
});

// ...

const errors = {
  'auth/user-not-found': 'The user with the provided email address does not exist'
};

// ...

export function PasswordResetForm({ actions }) {
  const styles = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const {
    sendPasswordResetEmail
  } = useAuth();

  const {
    notify
  } = useError();

  // ...

  const handleSubmit = async ({ username: email }) => {
    try {
      await sendPasswordResetEmail(email);

      const to = location.state?.from ?? '/';
      navigate(to);

    } catch (error) {
      notify(errors[error.code] ?? 'Unknown error when resetting password');
    }
  };

  const {
    handleCancel
  } = actions;

  // ...

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}

      onSubmit={handleSubmit}

      validateOnChange
    >
      {({ values, dirty, isValid, isSubmitting }) => (
        <Grid
          className={styles.container}
          container
          direction="column"
        >
          <Grid item>
            <T className={styles.header} variant="h5">
              RESET PASSWORD
            </T>
          </Grid>
          <Grid item>
            <Form
              noValidate
              autoComplete="off"
            >
              <Field
                name="username"
                placeholder="Email"

                as={TextField}
                className={styles.field}
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="username" />}
              />

              <Grid container justify="space-between">
                <Button
                  className={styles.buttonCancel}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  className={styles.button}
                  type="submit"
                  disabled={!(dirty && isValid) || isSubmitting}
                  disableElevation
                >
                  SEND RESET EMAIL
                </Button>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}