import React, { useState } from 'react';

import clsx from 'clsx'

import {
  Avatar,
  Grid,
  Typography as T,
  IconButton
} from '@material-ui/core';

import {
  Fullscreen, FullscreenExit
} from '@material-ui/icons'

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  ReactComponent as LeaveIcon
} from 'assets/icon_leave.svg';

import {
  ReactComponent as MutedIcon
} from 'assets/icon_muted.svg';

import {
  ReactComponent as UnmutedIcon
} from 'assets/icon_unmuted.svg';

import {
  AnnotationButton
} from './Controls.AnnotationButton';

import {
  AnnotationSelect
} from './Controls.AnnotationSelect';

import * as util from './util';

// ...

const useStyles = makeStyles(theme => ({
  root: props => ({
    padding: 24,

    ...props.root
  }),

  empty: {
    flex: 1
  },

  icon: {
    marginBottom: 15,
    width: 36,
    height: 36,
    cursor: 'pointer'
  },

  title: {
    color: 'white'
  },

  titleHoveredWindowed: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
    padding: '16px'
  },
  titleNotHoveredWindowed: {
    display: 'none'
  },

  avatar: {
    width: 60,
    height: 60,
    marginLeft: 15
  },
  avatarHoveredFullscreen: {
    cursor: 'pointer'
  },
  avatarHoveredWindowed: {
    cursor: 'hand'
  }
}));

// ...

function Controls({ context, actions, styleProps, children }, props) {
  
  const [isAvatarHovered, setAvatarHovered] = useState(false);
    
  const styles = useStyles(styleProps);
  
  const {
    mute,
    members,
    memberColors,
    titlesCollection
  } = context;

  const {
    setMute,
    onLeave
  } = actions;

  // ...

  const MuteIcon = mute
    ? MutedIcon
    : UnmutedIcon;

  // ...

  return (
    <div className={styles.root}>
      <Grid container>
        <Grid item>
          <Grid container direction="column">
            <LeaveIcon
              className={styles.icon}
              onClick={onLeave}
            />
            <MuteIcon
              className={styles.icon}
              onClick={() => setMute(!mute)}
            />
            <IconButton
              className={styles.icon}
              style={{backgroundColor: "white"}}
              onClick={() => context.setFullscreen(!context.isFullscreen)}
            >               
              {context.isFullscreen ? <FullscreenExit /> : <Fullscreen />} 
            </IconButton>
          </Grid>
        </Grid>
        <Grid className={styles.empty} item />
        <Grid item>
          <Grid container direction="column">
            {members.map(member => {
              const {
                id,
                image
              } = member;

              const color = memberColors[id];

              const borderColor = color
                ? `
                  rgb(${Math.round(color.r * 255)}, ${Math.round(color.g * 255)}, ${Math.round(color.b * 255)})
                `
                : {};

              const border = `4px solid ${borderColor}`;

              // ...

              const title = titlesCollection
                ? util.formatTitle(titlesCollection, member)
                : null;

              // ...

              return (
                <Grid
                  item

                  container
                  alignItems="center"
                  justify="flex-end"
                  styles={{
                    marginBottom: 20
                  }}
                >
                  { 
                    context.isFullscreen 
                      ? <T className={styles.title} variant="subtitle1">
                          {title}
                        </T>
                      : <T className={
                          clsx(
                            styles.title, 
                            isAvatarHovered // NOSONAR
                              ? styles.titleHoveredWindowed
                              : styles.titleNotHoveredWindowed 
                          )} 
                          variant="subtitle1">
                          {title}
                        </T>
                  }
                  <Avatar
                    key={id}
                    className={
                      clsx(
                        styles.avatar, 
                        isAvatarHovered && context.isFullscreen // NOSONAR
                          ? styles.avatarHoveredFullscreen
                          : styles.avatarHoveredWindowed
                      )
                    }
                    style={{
                      border
                    }}
                    src={image}
                    onMouseEnter={() => setAvatarHovered(true)}
                    onMouseLeave={() => setAvatarHovered(false)}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      {children}
    </div>
  )
}

Controls.AnnotationButton = AnnotationButton;
Controls.AnnotationSelect = AnnotationSelect;

export {
  Controls
};