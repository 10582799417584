import React from 'react';

import {
  Grid,
  Hidden,
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import Splash from 'assets/splash.jpg';

import {
  NewPasswordForm
} from './NewPasswordForm';

// ...

const useStyles = makeStyles({
  splash: {
    height: '100vh',
    backgroundImage: `url(${Splash})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
});

// ...

export function NewPassword({ actions }) {
  const styles = useStyles();

  // ...

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={7}
        md={5}

        container
        direction="column"
        justify="center"
      >
        <Grid item>
          <NewPasswordForm actions={actions} />
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid item sm={5} md={7}>
          <div className={styles.splash} />
        </Grid>
      </Hidden>
    </Grid>
  )
}